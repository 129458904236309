var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("MoveWithDiffBubbleGame", {
        attrs: {
          bgImg: _vm.bgImg,
          frontImg: _vm.frontImg,
          studentImg: _vm.studentImg,
          lessonNum: _vm.lessonNum,
          durationArr: _vm.durationArr,
          answerImgList: _vm.answerImgList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="sing-page-container"  :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-25.mp3"),
      },
      timeStampList: [4.6, 6.8, 11.5, 16.7, 21.5, 26.0],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Zhè, nà, zhè, nà, ",
            chineseWords: "这、那；这、那；",
          },
          {
            id: 2,
            spelling: "Nǐ kàn! Nà shì shénme?",
            chineseWords: "你看！那是什么？",
          },
          {
            id: 3,
            spelling: "Nà shì māo! Nà shì māo!",
            chineseWords: "那是猫！那是猫！",
          },
          {
            id: 4,
            spelling: "Nǐ kàn! Zhè shì shénme?",
            chineseWords: "你看!这是什么？",
          },
          {
            id: 5,
            spelling: "Zhè shì ...zhè shì ...māo!",
            chineseWords: "这是……这是……猫！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>

<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
      :lessonNum="25"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "zhè",
          hanzi: "这",
          english: "this",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-zhe-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "kàn",
          hanzi: "看",
          english: "to look",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-kan-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "nà",
          hanzi: "那",
          english: "that",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-na-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "māo",
          hanzi: "猫",
          english: "cat",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-mao-bubble.svg`),
        },
       {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "zhè/nà shì...",
          hanzi: "这/那是……",
          english: "this/that is...",
          image: require(`@/assets/img/12-Vocabulary/lesson-25-zhe-na.svg`),
          blue: true,
          longtext: true,
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>

<template>
  <div class="game-container">
    <SpeakingSentence :lesson="25" :gameList="gameList" :titleInfo="titleInfo" :hasMenu="false"/>
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList:[
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-0.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-1.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-2.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-3.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-4.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-5.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-25-1/image-6.svg`),
        },
      ],
       titleInfo:{
        pinyin:"Shéi?",
        hanzi:"谁？"
      }
     
    };
  },
  components: {
    SpeakingSentence,
  },
}
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>